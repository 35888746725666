<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          ref="searchWrapper"
          :rules="searchRule"
          label-position="right"
          label-width="100px"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Appeal_ID')" prop="appealId">
                <el-input
                  size="medium"
                  v-model.trim="formInline.carAppealId"
                  maxlength="19"
                  auto-complete="off"
                  placeholder="请输入准确信息"
                />
              </el-form-item>
              <el-form-item :label="$t('searchModule.phone_number')" prop="mobile">
                <el-input
                  :maxlength="11"
                  size="medium"
                  v-model.trim="formInline.mobile"
                  auto-complete="off"
                  placeholder="输入手机号"
                />
              </el-form-item>
              <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber">
                <el-autocomplete
                  ref="plateNumber"
                  v-model="formInline.plateNumber"
                  size="11"
                  valueKey="plateNumber"
                  class="inline-input"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="车牌号"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                />
              </el-form-item>
              <el-form-item :label="$t('searchModule.License_plate_color')" prop="plateColor">
                <el-select v-model="formInline.plateColor">
                  <el-option label="全部" value="" />
                  <el-option label="蓝色" :value="0" />
                  <el-option label="黄色" :value="1" />
                  <el-option label="绿色" :value="2" />
                  <el-option label="黄绿色" :value="3" />
                  <el-option label="黑色" :value="4" />
                  <el-option label="白色" :value="5" />
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Audit_status')" prop="authState">
                <el-select v-model="formInline.authState">
                  <el-option label="全部" value="" />
                  <el-option label="待审核" :value="1" />
                  <el-option label="已通过" :value="2" />
                  <el-option label="已拒绝" :value="3" />
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Appeal_time')">
                <el-date-picker
                  v-model="dateArray"
                  type="datetimerange"
                  range-separator="~"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']"
                >
                </el-date-picker>
                <i class="el-icon-date el-icon-date-custom"></i>
              </el-form-item>
            </div>
            <div class="col_right">
              <AuthorityComponent
                ComponentName="el-button"
                :permission="['button.query']"
                type="primary"
                icon="el-icon-search"
                :loading="loading"
                @click="
                  pageNum = 1;
                  searchData();
                "
                >{{ $t('button.search') }}</AuthorityComponent
              >
              <el-button @click="resetForm" icon="el-icon-delete" type="info">{{ $t('button.reset') }}</el-button>
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <el-table-column
            :prop="item.prop"
            align="center"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column :label="$t('searchModule.Appeal_time')" prop="createdTime" :sortable="true" align="center" />
          <AuthorityComponent
            ComponentName="el-table-column"
            :permission="['button.view']"
            align="center"
            label="操作"
          >
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="toDetail(scope.row)">详情</el-button>
            </template>
          </AuthorityComponent>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>

      <el-dialog
        title="查看"
        center
        :visible.sync="dialogTableVisible"
        width="750px"
        height="200px"
      >
        <div
          class="pic-box images"
          v-viewer="{
            title: false,
            toolbar: {
              zoomIn: 4,
              zoomOut: 4,
              oneToOne: 4,
              reset: 4,
              prev: 4,
              play: { show: 4, size: 'large' },
              next: 4,
              rotateLeft: 4,
              rotateRight: 4,
              flipHorizontal: 4,
              flipVertical: 4,
            },
          }"
        >
          <div class="pic">
            <div class="picBg"><img v-lazy="this.img1" v-if="this.img1" /></div>
            <p>行驶证</p>
          </div>
          <div class="pic">
            <div class="picBg"><img v-lazy="this.img2" v-if="this.img2" /></div>
            <p>身份证</p>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import "./viewer.css";
import Viewer from "v-viewer";
import Vue from "vue";
import { dateFormat, setIndex } from "../../common/js/public";
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999,
  },
});
export default {
  name: "complaintRecord",
  data() {
    const startDate = this.$moment().subtract(1, "years").format("YYYY-MM-DD 00:00:00");
    const endDate = this.$moment().format("YYYY-MM-DD 23:59:59");
    let checkPhone = (rule, value, callback) => {
      if (!/^\d{11}$/.test(value) && value) {
        callback(new Error("手机号格式不正确"));
      } else {
        callback();
      }
    };
    return {
      total: 0,
      pageSize: 15,
      pageNum: 1,
      dis: false,
      img1: "",
      img2: "",
      loading: false,
      dialogTableVisible: false,
      searchRule: {
        mobile: [
          {
            validator: checkPhone,
            trigger: "blur",
          },
        ],
      },
      ringChart: {},
      pieChart: {},
      tableCols: [
        {
          prop: "carappealId",
          label: "申诉ID",
        },
        {
          prop: "mobile",
          label: this.$t("list.phone_number"),
        },
        {
          prop: "plateColor",
          label: this.$t("list.License_plate_color"),
          formatter: (row) => {
            const result = ["蓝色", "黄色", "绿色", "黄绿色", "黑色", "白色"];
            return result[row.plateColor];
          },
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "",
          formatter: (row, column) => {
            if (row.plateNumber) {
              if (row.plateNumber.indexOf("无") != -1) {
                return "无牌车";
              } else {
                return row.plateNumber;
              }
            }
          },
        },
        // {
        // 	prop: 'content',
        // 	label: '申诉理由'
        // },
        {
          prop: "authState",
          label: "审核状态",
          formatter: (row) => {
            const result = ["", "待审核", "已通过", "已拒绝"];
            return result[row.authState];
          },
        },
      ],
      rules: {
        dealState: [
          {
            required: true,
            message: "请选择处理结果",
            trigger: "blur",
          },
        ],
        dealDescription: [
          {
            required: true,
            message: "请输入处理原因",
            trigger: "blur",
          },
        ],
      },
      tableData: [],
      formInline: { plateColor: "", authState: "" },
      formData: {
        dealState: 1,
        carappealId: "",
        dealDescription: "",
      },
      dateArray: [startDate, endDate],
      authority: this.$route.meta.authority.button,
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    querySearchAsync(queryString, cb) {
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/plateNumber/list",
        data: {
          part: queryString,
          size: 100,
        },
        success: (res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        },
      };
      this.$request(opt);
    },
    getImg({ memberId, carappealId }) {
      let opt = {
        method: "get",
        url: "/acb/2.0/carappeal/getcarappealpic",
        data: {
          memberId: memberId,
          carappealId: carappealId,
        },
        success: (res) => {
          this.img1 = res.value.driveLicenseImgUrl;
          this.img2 = res.value.idCardImgUrl;
        },
      };
      this.img1 = "";
      this.img2 = "";
      this.$request(opt);
    },
    checkDeatail(data) {
      this.dialogTableVisible = true;
      this.formData.carappealId = data.carappealId;
      this.getImg(data);
    },
    showLog() {
      if (!this.dateArray) {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
      if (this.dateArray && this.dateArray[0] > this.dateArray[1]) {
        this.$alert("结束时间不能小于开始时间", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
      // if (this.dateArray) {
      //   const startTime = this.$moment(this.dateArray[0]).unix()
      //   const endTime = this.$moment(this.dateArray[1]).unix()
      //   if ((endTime - startTime) / 3600 / 24 > 31) {
      //     this.$alert('查询时间不能大于31天', this.$t('pop_up.Tips'), {
      //       confirmButtonText: this.$t('pop_up.Determine')
      //     })
      //     return false
      //   }
      // }
      return true;
    },
    /* 车牌号 */
    getplateNumber() {
      let opt = {
        url: "/acb/2.0/plateNumber",
        method: "get",
        data: {
          part: "",
          size: 100,
        },
        success: (res) => {
          if (res.state == 0) {
            this.parkList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    unbindCarId({ carId, carappealId }) {
      let opt = {
        url: "/acb/2.0/carappeal/unbindCar/",
        method: "get",
        data: {
          carId,
          carappealId,
        },
        success: (res) => {
          if (res.state == 0) {
            this.$message({
              type: "success",
              message: "操作成功",
            });
            setTimeout(() => {
              this.searchData();
            }, 500);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$confirm("确认执行该操作?", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$request(opt);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    handleSelect(item) {
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
      this.pageNum = 1;
      this.searchData();
    },
    // 搜索
    searchData() {
      if (!this.showLog()) {
        return;
      }
      if (this.formInline.carAppealId && !/^\d+$/.test(this.formInline.carAppealId)) {
        this.$alert("申诉ID必须为数字", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      this.loading = true;
      const opt = {
        method: "get",
        url: "/acb/2.0/carappeal/list",
        data: {
          page: this.pageNum,
          pageSize: this.pageSize,
          carAppealId: this.formInline.carAppealId,
          mobile: this.formInline.mobile,
          carId: this.formInline.carId,
          plateColor: this.formInline.plateColor,
          authState: this.formInline.authState,
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            this.tableData = res.value.list;
            this.total = res.value.total * 1 || 0;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      if (this.dateArray) {
        opt.data.startTime = this.$moment(this.dateArray[0]).format("YYYY-MM-DD HH:mm:ss");
        opt.data.endTime = this.$moment(this.dateArray[1]).format("YYYY-MM-DD HH:mm:ss");
      }
      this.$request(opt);
    },
    // 去详情页
    toDetail(row) {
      this.$router.push({ path: "/complaintDetail", query: { carappealId: row.carappealId } });
    },
    resetForm() {
      const startDate = this.$moment().subtract(1, "years").format("YYYY-MM-DD 00:00:00");
      const endDate = this.$moment().format("YYYY-MM-DD 23:59:59");
      this.formInline = { plateColor: "", authState: "" };
      this.dateArray = [startDate, endDate];
    },
  },
  components: {},
  created() {
    // this.searchData()
  },
  activated() {
    this.searchData();
  },
  watch: {
    "formInline.plateNumber": {
      deep: true,
      handler: function (newV, oldV) {
        if (!newV) {
          this.formInline.carId = "";
        }
      },
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus"></style>
